// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button{
    border-radius: 15px;
    margin-bottom: 20px;
  }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-adicionar {
  margin-left: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.btn {
  cursor: pointer;
}

.paginacao {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 5px;
  outline: none;
}

.text-modal {
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.btn-success,
.btn-danger,
.btn-primary,
.btn-warning {
  margin-right: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/MaterialList.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;EACrB;;AAEF;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;;;;EAIE,kBAAkB;AACpB","sourcesContent":[".button{\n    border-radius: 15px;\n    margin-bottom: 20px;\n  }\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.btn-adicionar {\n  margin-left: auto;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 20px;\n}\n\nth, td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #f2f2f2;\n}\n\n.btn {\n  cursor: pointer;\n}\n\n.paginacao {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n\n.custom-modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: white;\n  padding: 20px;\n  border-radius: 5px;\n  outline: none;\n}\n\n.text-modal {\n  margin-bottom: 10px;\n}\n\n.form-control {\n  width: 100%;\n  padding: 10px;\n  margin-bottom: 15px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n}\n\n.btn-success,\n.btn-danger,\n.btn-primary,\n.btn-warning {\n  margin-right: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
