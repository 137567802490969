// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* InventoryList.css */
.container {
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .table {
    border: 2px solid #ddd;
  }
  
  .table th,
  .table td {
    border: 2px solid #ddd;
  }
  
  .table th {
    background-color: #f4f4f4;
    text-transform: uppercase;
  }
  
  .table tr:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .modal-content {
    background-color: #f8f9fa;
  }
  
  .modal-header {
    background-color: #007bff;
    color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  button {
    margin-top: 20px;
  }
  
  /* Adicione cores para diferentes condições */
  .good-condition {
    color: #155724;
  }
  
  .regular-condition {
    color: #856404;
  }
  
  .excellent-condition {
    color: #0c5460;
  }
  
  .poor-condition {
    color: #721c24;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/InventoryList.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;;IAEE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,6BAA6B;EAC/B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA,6CAA6C;EAC7C;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* InventoryList.css */\n.container {\n    margin-top: 50px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  h2 {\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .table {\n    border: 2px solid #ddd;\n  }\n  \n  .table th,\n  .table td {\n    border: 2px solid #ddd;\n  }\n  \n  .table th {\n    background-color: #f4f4f4;\n    text-transform: uppercase;\n  }\n  \n  .table tr:hover {\n    background-color: #f9f9f9;\n    cursor: pointer;\n  }\n  \n  .modal-content {\n    background-color: #f8f9fa;\n  }\n  \n  .modal-header {\n    background-color: #007bff;\n    color: #fff;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .modal-body {\n    padding: 20px;\n  }\n  \n  .form-group {\n    margin-bottom: 20px;\n  }\n  \n  button {\n    margin-top: 20px;\n  }\n  \n  /* Adicione cores para diferentes condições */\n  .good-condition {\n    color: #155724;\n  }\n  \n  .regular-condition {\n    color: #856404;\n  }\n  \n  .excellent-condition {\n    color: #0c5460;\n  }\n  \n  .poor-condition {\n    color: #721c24;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
