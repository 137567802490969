// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar ul li:hover {
    background-color: #f45a08;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar ul li a {
    text-decoration: none;
}

.sidebar ul li a:hover {
    color: #fff;
}

.sidebar ul li a {
    color: #fff;
}

/*.close_sider_icon{
    cursor: pointer;
}*/

.active {
    background-color: #d44f08;
    border-radius: 5px;
    color: white;
}
.not {
    background-color: #d44f08;
    
    color: white;
}

.sidebar-responsive {
    display: inline !important;
    z-index: 9999 !important;
    left: 0 !important;
    position: absolute;
    visibility: hidden;
}

.sidebar-responsive-show {
    visibility: visible;
}

@media only screen and (max-width: 978px) {
    /*#sidebar{
        display: none;
    }

    .sidebar__title > i{
        display: inline;
    }*/
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;;EAEE;;AAEF;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,yBAAyB;;IAEzB,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,wBAAwB;IACxB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;;;;;;MAME;AACN","sourcesContent":[".sidebar ul li:hover {\n    background-color: #f45a08;\n    color: #fff;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.sidebar ul li a {\n    text-decoration: none;\n}\n\n.sidebar ul li a:hover {\n    color: #fff;\n}\n\n.sidebar ul li a {\n    color: #fff;\n}\n\n/*.close_sider_icon{\n    cursor: pointer;\n}*/\n\n.active {\n    background-color: #d44f08;\n    border-radius: 5px;\n    color: white;\n}\n.not {\n    background-color: #d44f08;\n    \n    color: white;\n}\n\n.sidebar-responsive {\n    display: inline !important;\n    z-index: 9999 !important;\n    left: 0 !important;\n    position: absolute;\n    visibility: hidden;\n}\n\n.sidebar-responsive-show {\n    visibility: visible;\n}\n\n@media only screen and (max-width: 978px) {\n    /*#sidebar{\n        display: none;\n    }\n\n    .sidebar__title > i{\n        display: inline;\n    }*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
