// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open_sider_icon {
    cursor: pointer;
    color: rgb(11, 11, 12);
    font-size: 25px;
}

.profile {
    border: 1px rgb(51, 50, 49);
    width: 37px;
    border-radius: 50%;
}

/*.nav_icon{
    display: none;
}
.nav_icon > i{
    font-size: 26px;
    color: #a5aaad;
}

@media only screen and (max-width: 978px){

    .nav_icon{
        display: inline;
    }
} 

@media only screen and (max-width: 480px){

    .navbar_left{
        display: none;
    }
}*/`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;AACtB;;AAEA;;;;;;;;;;;;;;;;;;;;EAoBE","sourcesContent":[".open_sider_icon {\n    cursor: pointer;\n    color: rgb(11, 11, 12);\n    font-size: 25px;\n}\n\n.profile {\n    border: 1px rgb(51, 50, 49);\n    width: 37px;\n    border-radius: 50%;\n}\n\n/*.nav_icon{\n    display: none;\n}\n.nav_icon > i{\n    font-size: 26px;\n    color: #a5aaad;\n}\n\n@media only screen and (max-width: 978px){\n\n    .nav_icon{\n        display: inline;\n    }\n} \n\n@media only screen and (max-width: 480px){\n\n    .navbar_left{\n        display: none;\n    }\n}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
