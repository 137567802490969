// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    margin-top: 50px;
  }
  
  h2 {
    margin-bottom: 30px;
    color: #602f81;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #602f81;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #602f81;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #602f81;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #602f81;
  }
  
  .table .table {
    background-color: #602f81;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(96, 47, 129, 0.1);
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    color: #602f81;
    border: 1px solid #602f81;
    padding: 5px 10px;
    margin: 0 5px;
    background-color: transparent;
    cursor: pointer;
  }
  
  .pagination button.active,
  .pagination button:active {
    color: #fff;
    background-color: #602f81;
    border-color: #602f81;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination button:disabled:hover {
    background-color: transparent;
    color: #602f81;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/HistoryList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,cAAc;EAChB;;EAEA;;IAEE,gBAAgB;IAChB,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;IACE,sBAAsB;IACtB,gCAAgC;EAClC;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,6BAA6B;IAC7B,eAAe;EACjB;;EAEA;;IAEE,WAAW;IACX,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,6BAA6B;IAC7B,cAAc;EAChB","sourcesContent":[".container {\n    margin-top: 50px;\n  }\n  \n  h2 {\n    margin-bottom: 30px;\n    color: #602f81;\n  }\n  \n  .table {\n    width: 100%;\n    margin-bottom: 1rem;\n    color: #602f81;\n  }\n  \n  .table th,\n  .table td {\n    padding: 0.75rem;\n    vertical-align: top;\n    border-top: 1px solid #602f81;\n  }\n  \n  .table thead th {\n    vertical-align: bottom;\n    border-bottom: 2px solid #602f81;\n  }\n  \n  .table tbody + tbody {\n    border-top: 2px solid #602f81;\n  }\n  \n  .table .table {\n    background-color: #602f81;\n  }\n  \n  .table-striped tbody tr:nth-of-type(odd) {\n    background-color: rgba(96, 47, 129, 0.1);\n  }\n  \n  .pagination {\n    display: flex;\n    justify-content: center;\n    margin-top: 20px;\n  }\n  \n  .pagination button {\n    color: #602f81;\n    border: 1px solid #602f81;\n    padding: 5px 10px;\n    margin: 0 5px;\n    background-color: transparent;\n    cursor: pointer;\n  }\n  \n  .pagination button.active,\n  .pagination button:active {\n    color: #fff;\n    background-color: #602f81;\n    border-color: #602f81;\n  }\n  \n  .pagination button:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n  \n  .pagination button:disabled:hover {\n    background-color: transparent;\n    color: #602f81;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
