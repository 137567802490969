// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-content {
  display: flex;
  align-items: center;
  padding: 10px;
}

.popup-content img {
  max-width: 100px;
  height: auto;
  border-radius: 50%;
  margin-right: 10px;
  /* Adiciona espaço entre a imagem e o texto */
}

.popup-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Alinha o texto à esquerda, ajustável para à direita */
}


.status {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.status .status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-active {
  background-color: green;
}

.status-inactive {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/Map.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,wDAAwD;AAC1D;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".popup-content {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n}\n\n.popup-content img {\n  max-width: 100px;\n  height: auto;\n  border-radius: 50%;\n  margin-right: 10px;\n  /* Adiciona espaço entre a imagem e o texto */\n}\n\n.popup-text {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  /* Alinha o texto à esquerda, ajustável para à direita */\n}\n\n\n.status {\n  display: flex;\n  align-items: center;\n  margin-top: 5px;\n}\n\n.status .status-indicator {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  margin-right: 5px;\n}\n\n.status-active {\n  background-color: green;\n}\n\n.status-inactive {\n  background-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
