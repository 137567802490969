// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.management-list {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.management-list h1 {
  font-size: 24px;
  color: #0d0c0e;
  margin-bottom: 15px;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #131014;
  border-radius: 8px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s;
  width: 250px;
}

.list-group-item:hover {
  transform: scale(1.03);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  background-color:#d44f08;
  color: #fff;
}

.list-group-item:hover .card-title,
.list-group-item:hover .card-text {
  color: #fff;
}

.card-title {
  font-size: 20px;
  margin-bottom: 8px;
  color: #403880;
  border-color:  rgb(232, 132, 26);
  padding-left: 20px;
}

.card-text {
  font-size: 14px;
  color: #555;
}

.card1 {
  width: 100%;
  padding-right: 20px;
  padding: 20px;
}

.card-body {
  margin-bottom: 15px;
  padding-left: 10px;
}

.icon {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 8px;
}

.styleborder {
  color:#231c5d;
  border: 1px solid  rgb(243, 238, 238);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementList.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,sCAAsC;EACtC,wBAAwB;EACxB,WAAW;AACb;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,qCAAqC;AACvC","sourcesContent":[".management-list {\n  background-color: #f0f0f0;\n  padding: 10px;\n  border-radius: 8px;\n  text-align: center;\n}\n\n.management-list h1 {\n  font-size: 24px;\n  color: #0d0c0e;\n  margin-bottom: 15px;\n}\n\n.list-group-item {\n  background-color: #fff;\n  border: 1px solid #131014;\n  border-radius: 8px;\n  margin-bottom: 15px;\n  cursor: pointer;\n  transition: all 0.3s;\n  width: 250px;\n}\n\n.list-group-item:hover {\n  transform: scale(1.03);\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);\n  background-color:#d44f08;\n  color: #fff;\n}\n\n.list-group-item:hover .card-title,\n.list-group-item:hover .card-text {\n  color: #fff;\n}\n\n.card-title {\n  font-size: 20px;\n  margin-bottom: 8px;\n  color: #403880;\n  border-color:  rgb(232, 132, 26);\n  padding-left: 20px;\n}\n\n.card-text {\n  font-size: 14px;\n  color: #555;\n}\n\n.card1 {\n  width: 100%;\n  padding-right: 20px;\n  padding: 20px;\n}\n\n.card-body {\n  margin-bottom: 15px;\n  padding-left: 10px;\n}\n\n.icon {\n  font-size: 28px;\n  vertical-align: middle;\n  margin-right: 8px;\n}\n\n.styleborder {\n  color:#231c5d;\n  border: 1px solid  rgb(243, 238, 238);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
